<template>
  <div class="page" v-loading="loading">
    <el-container style="height: 100%;padding: 30px 0;border-bottom: 1px solid #cccccc;">
      <el-aside width="250px" style="background-color: rgb(238, 241, 246);padding-bottom: 10px;">
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose">
          <el-menu-item index="1" @click="toQuestionList()">
            <i class="el-icon-menu"></i>
            <span slot="title">题目列表</span>
          </el-menu-item>
          <el-menu-item index="2" @click="toUserAnswerList()">
            <i class="el-icon-document"></i>
            <span slot="title">答题记录</span>
          </el-menu-item>
          <el-menu-item index="3" @click="toDayList()">
            <i class="el-icon-document"></i>
            <span slot="title">每日一练</span>
          </el-menu-item>
          <el-menu-item index="4" @click="toRankList()">
            <i class="el-icon-document"></i>
            <span slot="title">排名</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <!--            <router-view/>-->
        <div v-if="flag" >
          考试科目：
          <el-select v-model="value" placeholder="请选择" clearable  style="width: 200px;margin-right: 10px;margin-top: -50px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
            </el-option>
          </el-select>

          科目等级：
          <el-select v-model="value2" placeholder="请选择" clearable style="width: 200px;margin-right: 10px">
            <el-option
                v-for="item in options2"
                :key="item.value2"
                :label="item.label2"
                :value="item.value2">
            </el-option>
          </el-select>
          题型：
          <el-select v-model="value3" placeholder="请选择" clearable style="width: 200px">
            <el-option
                v-for="item in options3"
                :key="item.value3"
                :label="item.label3"
                :value="item.value3">
            </el-option>
          </el-select>

          <div>
           搜索题目：
            <el-input placeholder="请输入序号或者题目" v-model="content" clearable style="width: 77.5%;margin-bottom: 30px;margin-top: 15px" >
              <el-button slot="append" type="primary" style="width: 80px;color: #19d3ea;"
                         icon="el-icon-search" maxlength="10"
                         @click="getQuestionList">搜题
              </el-button>
            </el-input>
          </div>

          <template>

            <!--            <questionDetail ref="mainDetails" :questionDetail="questionDetail"></questionDetail>-->
            <el-table
                :data="tableData"
                style="width: 100%"
                :row-class-name="tableRowClassName">
              <el-table-column
                  prop="id"
                  label="序号"
                  width="70"
                  >
              </el-table-column>
              <el-table-column
                  prop="shortTitle"
                  style="width: 55%;"
                  label="题目"
                  :show-overflow-tooltip="true"
                  width="550px">
              </el-table-column>
              <el-table-column
                  prop="type"
                  style="width: 25%"
                  label="标签"
              >
                <template slot-scope="scope">
                  <!--            <el-tag class="tag" type="info" size="mini" v-for="i in item.paperLabels.split(',')" :key="i">{{i}}</el-tag>-->
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===0">
                    Scratch
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===1">
                    C++
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===2">
                    Python
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===3">
                    NOIP
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===4">
                    机器人技术
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===5">
                    科技素养/计算思维
                  </el-tag>
                  <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.type ===6">
                    无人机技术
                  </el-tag>
                  <!--                  <el-tag el-tag class="tag" size="small" type="success" v-else>其他</el-tag>-->
                </template>
              </el-table-column>
              <!--        <el-table-column-->
              <!--            width="200" label="难度">-->
              <!--          <template slot-scope="scope">-->
              <!--            <el-rate v-model="scope.row.difficult" disabled=""></el-rate>-->
              <!--          </template>-->
              <!--        </el-table-column>-->

              <el-table-column
                  prop="questionType"
                  style="width: 5%"
                  label="题型"
                  filter-placement="bottom-end">
                <template slot-scope="scope">
                  <el-tag class="tag" size="small" type="success" v-if="scope.row.questionType ===0">
                    单选题
                  </el-tag>
                  <el-tag class="tag" size="small" type="danger" v-if="scope.row.questionType ===1">
                    判断题
                  </el-tag>
                  <el-tag class="tag" size="small" type="info" v-if="scope.row.questionType ===2">
                    多选题
                  </el-tag>
                  <el-tag class="tag" size="small" type="success" v-if="scope.row.questionType ===3">
                    简答题
                  </el-tag>
                  <el-tag class="tag" size="small" type="warning" v-if="scope.row.questionType ===4">
                    编程题
                  </el-tag>
                  <el-tag size="small" type="success" v-if="scope.row.questionType ===5">填空题</el-tag>
                </template>
              </el-table-column>

              <el-table-column
                  label="操作"
                  style="width: 10%">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="gotoQuestionDetail(scope.row.id)"
                      type="text"
                      size="small">
                    开始答题
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
        <!--                <div v-if="flag22">-->
        <!--                    <template>-->
        <!--                        <el-table-->
        <!--                                :data="tableData2"-->
        <!--                                style="width: 100%">-->
        <!--                            <el-table-column-->
        <!--                                    prop="id"-->
        <!--                                    label="序号"-->
        <!--                                    width="100">-->
        <!--                            </el-table-column>-->
        <!--                            <el-table-column-->
        <!--                                    prop="questionId"-->
        <!--                                    label="题目编号"-->
        <!--                                    width="100">-->
        <!--                            </el-table-column>-->
        <!--                            <el-table-column-->
        <!--                                    prop="examTextContent.content"-->
        <!--                                    label="标题"-->
        <!--                                    :show-overflow-tooltip="true"-->
        <!--                                    width="400">-->
        <!--                            </el-table-column>-->
        <!--                            <el-table-column-->
        <!--                                    prop="doRight"-->
        <!--                                    label="状态"-->
        <!--                                    width="100">-->
        <!--                            </el-table-column>-->
        <!--&lt;!&ndash;                            <el-table-column&ndash;&gt;-->
        <!--&lt;!&ndash;                                    prop="address"&ndash;&gt;-->
        <!--&lt;!&ndash;                                    label="耗时">&ndash;&gt;-->
        <!--&lt;!&ndash;                            </el-table-column>&ndash;&gt;-->
        <!--                            <el-table-column-->
        <!--                                    prop="createTime"-->
        <!--                                    label="提交时间"-->
        <!--                                    width="150">-->
        <!--                            </el-table-column>-->

        <!--                        </el-table>-->
        <!--                    </template>-->
        <!--                </div>-->
        <div v-if="flag2">
          暂未开放
        </div>
        <div v-if="flag3">
          暂未开放
        </div>
        <div v-if="flag4">
          暂未开放
        </div>
      </el-main>
    </el-container>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                   style="margin-top: 20px;text-align: center">
    </el-pagination>
  </div>
</template>

<script>
import titleSelct2 from "@/components/titleSelect2.vue"
import questionDetail from "./components/questionDetail.vue"
import {
  getQuestionDetail,
  userAnswerList,
  countByDaySubmit,
  questionList
} from "@/api/index.js"

export default {
  components: {
    titleSelct2,
    questionDetail
  },
  data() {
    return {
      maodian: '#1',
      // allNumberArr: [],
      answerArr: [],
      questionDetail: {},
      flag: true,
      flag2: false,
      flag22: false,
      flag3: false,
      flag4: false,
      content: null,
      activeName: 'first',
      tableData: [],
      loading: false,
      allInfo: {
        total: 0
      },
      nowType: {},
      currentPage: 1,
      pageSize: 10,
      haveMore: false,
      noMore: false,
      isLoading: true,
      tableData2: [],

      options: [
        {
          value: null,
          label: '全部'
        },{
        value: 0,
        label: 'Scratch/图形化'
      }, {
        value: 1,
        label: 'C/C++'
      }, {
        value: 2,
        label: 'Python'
      }, {
        value: 4,
        label: '机器人技术'
      }, {
        value: 3,
        label: 'NOIP'
      }, {
        value: 5,
        label: '科技素养/计算思维'
      }, {
        value: 6,
        label: '无人机技术'
      }],
      value: null,
      options2: [
        {
          value2: null,
          label2: '全部'
        }, {
        value2: 1,
        label2: '一级'
      }, {
        value2: 2,
        label2: '二级'
      }, {
        value2: 3,
        label2: '三级'
      }, {
        value2: 4,
        label2: '四级'
      }, {
        value2: "98",
        label2: "蓝桥杯"
      }, {
        value2: "0",
        label2: "NOC/NCT"
      }, {
        value2: "99",
        label2: "练习题"
      }, {
        value2: "404",
        label2: "五级/六级/其他"
      }],
      value2: null,
      options3: [
        {
          value3: null,
          label3: '全部'
        }, {
          value3: 0,
          label3: '单选题'
        }, {
          value3: 1,
          label3: '判断题'
        }, {
          value3: 2,
          label3: '多选题'
        }, {
          value3: 3,
          label3: '简答题'
        }, {
          value3: 4,
          label3: '编程题'
        }, {
          value3: "5",
          label3: "填空题"
        }],
      value3: null
    };
  },
  watch: {
    nowType: {
      handler: function (n, o) {
        this.resetData();
        this.loading = true
        this.getQuestionList()
      },
      deep: true
    }
  },
  mounted() {
    let val = {
      type: 0,
      level: null
    }
    this.nowType = val;
    // this.getQuestionList()

    // this.testPaperId = this.$route.query.id;
    // this.getInfo(this.testPaperId)
    // this.getQuestionInfo(17)
  },
  methods: {
    filterTag(value, row) {
      console.log(value)
      return row.questionType === value;
    },
    isAnswerArr(item) {
      this.answerArr = item
    },
    toQuestionList() {
      this.flag = false,
          this.flag2 = false,
          this.flag3 = false,
          this.flag4 = false,
          this.flag = true,
          this.getQuestionList()
    },
    toUserAnswerList() {
      this.flag2 = false,
          this.flag = false,
          this.flag3 = false,
          this.flag4 = false,
          this.flag2 = true
      // this.userAnswerList()
    },
    toDayList() {
      this.flag3 = false,
          this.flag3 = true,
          this.flag = false,
          this.flag2 = false,
          this.flag4 = false
    },
    toRankList() {
      this.flag4 = false,
          this.flag4 = true,
          this.flag = false,
          this.flag2 = false,
          this.flag3 = false
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    resetData() {
      this.loading = false,
          this.examinationRecordArray = [],
          this.allInfo = {
            total: 0
          },
          this.currentPage = 1,
          this.pageSize = 10,
          this.haveMore = false,
          this.noMore = false,
          this.isLoading = true
    },
    changeSel(val) {
      this.type = val.type
      this.getQuestionList()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.getQuestionList()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getQuestionList()
    },
    //题目列表
    getQuestionList() {
      this.loading = true
      let param = {
        content: this.content,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.value,
        level: this.value2,
        questionType: this.value3,
      }
      questionList(param).then(res => {
        if (res.code === "0000") {
          this.allInfo = res.data
          this.tableData = res.data.list
          // console.log(res.data.list)
          this.loading = false
        }
      }).catch(() => {
        this.$router.push({
          path: '/'
        })
      });
    },
    getQuestionInfo(id) {
      this.loading = true
      getQuestionDetail(id).then(res => {
        if (res.code === "0000") {
          this.questionDetail = res.data
          let numberArr = []
          // res.data.titleItems.forEach(item => {
          //   if (item.questionItems) {
          //     item.questionItems.forEach(item1 => {
          //       numberArr.push(item1.itemOrder)
          //     })
          //   }
          // })
          this.allNumberArr = numberArr
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      });
    },
    gotoQuestionDetail(id) {
      countByDaySubmit().then(res => {
        if (res.code === "0000") {

          let routeData = this.$router.resolve({
            path: "/questionDetail?id=" + id
          });
          window.open(routeData.href, '_blank');
        }
      }).catch(() => {
        // this.allInfo = res.data
        // this.tableData = res.data.list
        // this.loading = false
        this.getQuestionList()
      });

    },
    // 题库答题记录
    // userAnswerList() {
    //     this.loading = true
    //     let param = {
    //         content: this.content,
    //         currentPage: this.currentPage,
    //         pageSize: this.pageSize,
    //         type: Number(this.type),
    //         // level: Number(this.nowType.level),
    //     }
    //     userAnswerList().then(res => {
    //         if (res.code === "0000") {
    //             this.tableData2=[]
    //             this.tableData2 = res.data
    //             console.log(this.tableData2)
    //             this.loading = false
    //         }
    //     }).catch(() => {
    //         this.$router.push({
    //             path: '/'
    //         })
    //     });
    // },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    }
  }
};
</script>
<style>
.el-tooltip__popper {
  max-width: 500px;
}
</style>
<style scoped>

.el-divider__text {
  background-color: #f5f5f5;
  color: #808080;
}


/*解决双滚动条问题*/
.el-main {
  overflow: hidden;
}
</style>
