<template>
	<div style="padding-bottom: 30px;">
		<div class="typeSel">
			<p>题目类型:</p>
			<div class="radioGroup">
				<el-radio-group v-model="programingLanguage" size="mini">
					<el-radio-button :label="item.value" :key="item.value" v-for="item in programingLanguageType">
						{{item.name}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		watch: {
			programingLanguage: {
				handler: function(n, o) {
					let param = {
						type: this.programingLanguage,
						level: this.subject
					}
					this.$emit("changeSel", param)
				},
				deep: true
			},
			subject: {
				handler: function(n, o) {
					let param = {
						type: this.programingLanguage,
						level: this.subject
					}
					this.$emit("changeSel", param)
				},
				deep: true
			},
		},
		data() {
			return {
				programingLanguage: "0",
				programingLanguageType: [{
						name: "Scratch",
						value: "0"
					},
					{
						name: "C/C++",
						value: "1"
					},
					{
						name: "Python",
						value: "2"
					},
					{
						name: "NOIP",
						value: "3"
					},
					{
						name: "机器人技术",
						value: "4"
					},
					{
						name: "科技素养/计算思维",
						value: "5"
					},
					// {
					// 	name: "无人机技术",
					// 	value: "6"
					// }
				],
				subject: null,
				subjectType: [{
					  name: "全部",
					  value: null
					  },{
						name: "模拟题",
						value: "0"
					},
					{
						name: "一级",
						value: "1"
					},
					{
						name: "二级",
						value: "2"
					},
					{
						name: "三级",
						value: "3"
					},
					{
						name: "四级",
						value: "4"
					},
					  {
						name: "蓝桥杯",
						value: "98"
					  },
					{
						name: "练习题",
						value: "99"
					},{
					name: "其他",
					value: "404"
				  }
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep.el-radio-button:first-child .el-radio-button__inner {
		border-left: none;
		border-radius: 5px
	}

	::v-deep.el-radio-button:last-child .el-radio-button__inner {
		// border-left: none;
		border-radius: 5px
	}

	::v-deep .el-radio-button__inner {
		background: none;
		border: none;
		border-radius: 5px
	}
	.typeSel {
		display: flex;
		margin-top: 10px;

		p {
			font-size: 18px;
			font-weight: 500;
		}

		.radioGroup {
			margin-left: 20px;
		}
	}
</style>
